.App {
  text-align: center;
  position: absolute;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

img.loader {
  width: 0;
  height: 0;
}

img.blurb {
  margin-top: 15%;
  width: 90%;
}

img.logo {
  position: relative;
  width: 20cqw;
  margin: 4cqw;
}

img.author {
  position: absolute;
  top:  5%;
  width: 55cqw;
  translate: -50% 0%;
  border-style: double;
  border-color: #f9ed6aaa;
  border-width: 5px;
  border-radius: 2em;
}

p.author {
  position: absolute;
  top: 36%;
  font-family: "Libre Baskerville";
  text-align: center;
  font-size: 4cqw;
  padding: 0% 12%;
  color: #f9ed6aff;
}

p.stinger {
  font-family: "Libre Baskerville";
  text-align: center;
  font-size: 4cqw;
  padding: 0% 5%;
  color: #f9ed6aff;
}

div.blurbDiv {
  margin-top: 10%;
  max-height: 75%;
  overflow-y: auto;
}

p.blurb {
  font-family: "Libre Baskerville";
  text-align: justify;
  font-size: calc(max(3.2cqw, 1em));
  padding: 0% 5%;
  color: #f9ed6aff;
}

p.buyComingSoon {
  font-family: "Libre Baskerville";
  text-align: center;
  font-size: 5cqw;
  padding: 15% 0%;
  color: #f9ed6aff;
}

div.buyComingSoon {
  position: absolute;
  top: 25%;
  left: 10%;
  width: 80%;
  height: 13%;
  background-color: #000000cc;
  border-style: double;
  border-color: #f9ed6a77;
  border-width: 5px;
  border-radius: 2em;
  animation-name: buyComingSoonFadeIn;
  animation-duration: 1s;
  animation-delay: 3s;
  animation-fill-mode: both;
}

@keyframes buyComingSoonFadeIn {
  0% {
    opacity: 0%;
  }
  100% {
    opacity: 100%;
  }
}

/* 
@container (max-width: 32em) {
  p.info {
    font-size: 1em;
  }
} */
