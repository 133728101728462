button.maze {
    width: 12cqw;
    height: 12cqw;
    border: none;
    border-radius: 4cqw;
    background-color: #e47d0411;
    transition-duration: 10s;
    animation-name: mazeButtonDrift;
    animation-duration: 15s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

button.maze:hover {
    background-color: #f9ed6a22;
}

button.southwest {
    position: absolute;
    left: 0%;
    bottom: 6%;
}

@keyframes mazeButtonDrift {
    0% {
        translate: -3% -3%;
    }
    25% {
        translate: 3% -3%;
    }
    50% {
        translate: 3% 3%;
    }
    75% {
        translate: -3% 3%;
    }
    100% {
        translate: -3% -3%;
    }
}
