img.splash {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 110%;
    z-index: -1;
    animation: fadeIn 2s linear, pulse 1s ease-in-out 2s infinite alternate;
}

@keyframes fadeIn {
    0% {
        display: none;
        opacity: 0%;
    }
    60% {
        display: block;
        opacity: 0%;
    }
    100% {
        opacity: 100%;
    }
}

@keyframes pulse {
    0% {
        opacity: 100%;
    }
    100% {
        opacity: 90%;
    }
}
