div.mapContainer {
    background-color: #000000cc;
    width: 90%;
    height: 75%;
    overflow: hidden;
    margin-top: 10%;
    margin-left: auto;
    margin-right: auto;
    border-style: double;
    border-color: #f9ed6aaa;
    border-width: 5px;
    border-radius: 2em;
}

canvas.mapCanvas {
    width: 100%;
    height: 100%;
}

button.map {
    position:absolute;
    height: 9cqw;
    font-family: "Libre Baskerville";
    font-size: 3.8cqw;
    font-variant-caps: small-caps;
    padding: 2cqw 2cqw;
    color: white;
    background-color: #e47d0455;
    border: none;
    border-radius: 4cqw;
    transition-duration: 0.5s;
}

button.map:hover {
    color: black;
    background-color: #f9ed6aaa;
}

button.mapLoadHighRes {
    left: 8%;
    top: 7%;
}

button.mapZoom {
    position:absolute;
    width: 9cqw;
    height: 9cqw;
    font-family: "Libre Baskerville";
    font-size: 4cqw;
    font-weight: bold;
    font-variant-caps: small-caps;
    padding: 2cqw 2cqw;
    color: white;
    background-color: #e47d0455;
    border: none;
    border-radius: 4cqw;
    transition-duration: 0.5s;
}

button.mapZoomIn {
    left: 73%;
    top: 7%;
}

button.mapZoomOut {
    left: 83%;
    top: 7%;
}

button.mapHidden {
    display: none;
}

p.mapLoadingText {
    position: absolute;
    left: 8%;
    top: 7%;
    pointer-events: none;
    font-family: "Libre Baskerville";
    font-size: 3.8cqw;
    font-weight: bold;
    padding: 0% 3%;
    color: #f9ed6aff;
    animation-name: mapLoadingTextFade;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    animation-duration: 1.5s;
    animation-direction: alternate;
}

@keyframes mapLoadingTextFade {
    0% {
        opacity: 0%;
    }

    100% {
        opacity: 100%;
    }
}
