div.screen {
    display: none;
    background-color: transparent;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    aspect-ratio: 1/2;
}

div.screenContent {
    position: absolute;
    width: 100%;
    height: 100%;
    container-type: inline-size;
    container-name: "screen";
}

img.screenBackground {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 110%;
    z-index: -2;
    animation-name: screenBackgroundFadeIn;
    animation-duration: 0.1s;
    animation-timing-function: linear;
}

@keyframes screenBackgroundFadeIn {
    0% {
        opacity: 0%;
    }
    100% {
        opacity: 100%;
    }
}
