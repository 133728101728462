button.basic {
    font-family: "Libre Baskerville";
    font-size: 5cqw;
    font-variant-caps: small-caps;
    padding: 2cqw 2cqw;
    color: white;
    background-color: #e47d0455;
    border: none;
    border-radius: 4cqw;
    transition-duration: 0.5s;
}
 
/*@container (min-width: 22em) {
    button.basic {
        font-size: 1.3em;
        padding: 0.6em 0.6em;
        border-radius: 1em;
    }
}*/

button.basic:hover {
    color: black;
    background-color: #f9ed6aaa;
}

button.centered {
    margin-left: auto;
    margin-right: auto;
}

button.startButtonPlacement {
    position: absolute;
    bottom: 8%;
    left: 50%;
    transform: translate(-50%, -50%);
}

button.authorBack {
    position: absolute;
    bottom: 6%;
    left: 50%;
    transform: translate(-50%, -50%);
}

button.newline {
    margin-top: 5%;
}

button.inlineWide {
    margin-top: 5%;
    margin-left: 30%;
}

button.inlineNarrow {
    margin-top: 5%;
    margin-left: 15%;
}

button.storyOverview {
    position: absolute;
    bottom: 20%;
    left: 5%;
    transform: translate(0%, -50%);
}

button.buy {
    position: absolute;
    bottom: 20%;
    right: 5%;
    transform: translate(0%, -50%);
}

button.maps {
    position: absolute;
    bottom: 10%;
    left: 5%;
    transform: translate(0%, -50%);
}

button.author {
    position: absolute;
    bottom: 10%;
    right: 5%;
    transform: translate(0%, -50%);
}

button.ebook {
    position: absolute;
    top: 20%;
    left: 22%;
    transform: translate(0%, -50%);
    animation-name: ebookAnimateIn;
    animation-duration: 0.6s;
    animation-delay: 0.75s;
    animation-fill-mode: both;
}

@keyframes ebookAnimateIn {
    0% {
        left: 122%;
        opacity: 0%;
    }
    100% {
        left: 22%;
        opacity: 100%;
    }
}

button.paperback {
    position: absolute;
    top: 20%;
    transform: translate(0%, -50%);
    animation-name: paperbackAnimateIn;
    animation-duration: 0.6s;
    animation-delay: 1s;
    animation-fill-mode: both;
}

@keyframes paperbackAnimateIn {
    0% {
        left: 153%;
        opacity: 0%;
    }
    100% {
        left: 53%;
        opacity: 100%;
    }
}

button.hardcover {
    position: absolute;
    top: 30%;
    transform: translate(0%, -50%);
    animation-name: hardcoverAnimateIn;
    animation-duration: 0.6s;
    animation-delay: 1.25s;
    animation-fill-mode: both;
}

@keyframes hardcoverAnimateIn {
    0% {
        left: 116%;
        opacity: 0%;
    }
    100% {
        left: 16%;
        opacity: 100%;
    }
}

button.audiobook {
    position: absolute;
    top: 30%;
    transform: translate(0%, -50%);
    animation-name: audiobookAnimateIn;
    animation-duration: 0.75s;
    animation-delay: 1.5s;
    animation-fill-mode: both;
}

@keyframes audiobookAnimateIn {
    0% {
        left: 183%;
        opacity: 0%;
    }
    100% {
        left: 53%;
        opacity: 100%;
    }
}

button.fadeIn {
    animation-name: fadeIn;
    animation-duration: 2s;
    animation-delay: 1s;
    animation-fill-mode: both;
}

@keyframes fadeIn {
    0% {
        opacity: 0%;
    }

    100% {
        opacity: 100%;
    }
}